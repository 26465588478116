import React, { createContext, useState } from 'react';

export const MyContext = createContext();

function Provider({children}) {
    const [scrollMenuStatus, setScrollMenuStatus] = useState(0);

    // Use useCallback for the function to avoid unnecessary re-renders
    const changeScrollMenuStatus = (value) => {
      setScrollMenuStatus(value);
    };

      // Use useMemo for the context value to avoid creating a new object on each render
  const contextValue = React.useMemo(() => ({
    scrollMenuStatus,
    changeScrollMenuStatus,
  }), [scrollMenuStatus]);

  return (
    <MyContext.Provider value={contextValue}>
      {children}
    </MyContext.Provider>
  );
}
function AppProvider({ element }) {
  return <Provider>
    {element}
  </Provider>
}

export default AppProvider;
