import Provider from './src/components/Provider';

export const wrapRootElement = Provider;

export const shouldUpdateScroll = ({
    prevRouterProps,
    routerProps: { location },
    getSavedScrollPosition
}) => {
    const currentPosition = getSavedScrollPosition(location)

    if (prevRouterProps) {
      window.scrollTo(...(currentPosition || [0, 0]))
    }

    return false
}
